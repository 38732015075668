import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-fade';

const Carousel = ({ slides, color, colorDegradado }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleBulletClick = (index) => {
    setCurrentIndex(index);
  };

  return (
    <div className="relative w-full text-white overflow-x-hidden">
      <Swiper
        spaceBetween={30}
        effect={'slide'}
        loop={true}
        autoplay={{
          delay: 5500,
          disableOnInteraction: false,
        }}
        onSlideChange={(swiper) => setCurrentIndex(swiper.realIndex)} // Actualizar el estado cuando cambia de slide
        modules={[Navigation, Autoplay]}
      >
        {slides.map((slide, index) => (
          <SwiperSlide key={index}>
            <div className='relative w-full h-full'>
              <div className='absolute inset-0 flex flex-col justify-center items-center gap-6'>
                <h1 className='text-white text-center text-[18px] md:text-[28px] font-bold' dangerouslySetInnerHTML={{ __html: slide.title }} />
                <div className='relative mx-auto w-[250px] md:w-[364px] mb-10 md:mb-28'>
                  <div
                    className="absolute before:absolute w-full h-[6px] md:h-[8px]"
                    style={{
                      backgroundImage: `linear-gradient(to right, ${color}, ${colorDegradado === '' ? '#fff' : colorDegradado})`,
                    }}
                  ></div>
                </div>
                <p className='text-white px-5 text-[28px] leading-[32px] md:text-[68px] mt-8 md:leading-[68px] mb-10 md:mb-28 text-center text-balance' dangerouslySetInnerHTML={{ __html: slide.text }} />
              </div>
              <img
                src={slide.imageUrl}
                alt={slide.title}
                className="w-full h-full object-cover min-h-[600px]"
              />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>

      {/* Bullets personalizados */}
      <div className="absolute z-[99999] top-10 right-10 flex justify-center">
        {slides.map((_, index) => (
          <div
            key={index}
            className={`w-3 h-3 mx-1 rounded-full cursor-pointer`}
            style={{
              backgroundColor: currentIndex === index ? color : 'white',
            }}
            onClick={() => handleBulletClick(index)}
          ></div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
