import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';

const Blog = () => {
    const [posts, setPosts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [loading, setLoading] = useState(true);
    const [acfData, setAcfData] = useState(null);
    const [fondoUrl, setFondoUrl] = useState('');

    const postsPerPage = 4;

    const fetchPosts = async () => {
        try {
            const response = await fetch('https://interprika.com/cms/wp-json/wp/v2/posts');
            const data = await response.json();
            setPosts(data);
            setTotalPages(Math.ceil(data.length / postsPerPage));
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        // Fetch data from the API
        const fetchData = async () => {
            try {
                const response = await fetch('https://interprika.com/cms/wp-json/wp/v2/pages/183');
                const data = await response.json();

                // Guarda los datos del ACF en el estado
                setAcfData(data.acf);

                // Si existe el ID del fondo, hacemos un fetch para obtener la URL de la imagen
                if (data.acf?.fondo) {
                    const imageResponse = await fetch(`https://interprika.com/cms/wp-json/wp/v2/media/${data.acf.fondo}`);
                    const imageData = await imageResponse.json();
                    setFondoUrl(imageData.source_url); // Guarda la URL del fondo en el estado
                }

                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        fetchPosts();
    }, []);

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePreviousPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

    // Los 4 posts más recientes para la barra lateral
    const recentPosts = posts.slice(0, 4);

    if (loading) {
        return (
            <div className='w-full h-full bg-black fixed top-0 z-[999999999] flex flex-col justify-center items-center'>
                <img className='w-[300px] max-md:w-[150px] h-[44px] object-contain' src='./images/logo.png' alt="" />
            </div>
        );
    }

    return (
        <div className='pb-10'>
            <div className='bg-[#000] overflow-hidden relative'>
                {fondoUrl && (
                    <img
                        className='w-full max-md:h-[300px] object-contain'
                        src={fondoUrl}
                        alt="Header Background"
                    />
                )}
                <div className='flex flex-col justify-center items-center w-full h-full top-[-50px] absolute'>
                    <motion.div
                        className='text-white [&_p]:text-[22px] [&_p]:md:text-[48px] [&_p]:leading-[28px] [&_p]:md:leading-[48px] pb-2'
                        dangerouslySetInnerHTML={{ __html: acfData?.titulo }}
                        initial="hidden"
                        whileInView="visible"
                        variants={{
                            hidden: { opacity: 0, y: -200 },
                            visible: { opacity: 1, y: 0 },
                        }}
                        transition={{ duration: 0.5 }}
                    />
                    <motion.h4
                        className="text-white"
                        initial="hidden"
                        whileInView="visible"
                        variants={{
                            hidden: { opacity: 0, x: -200 },
                            visible: { opacity: 1, x: 0 },
                        }}
                        transition={{ duration: 0.5 }}
                        dangerouslySetInnerHTML={{ __html: acfData?.subtitulo }}
                    />
                </div>
                <div className='mt-[-100px]'>
                    <div className='clip-diagonal-white'></div>
                </div>
            </div>
            <div className='w-[1280px] 2xl:w-[1440px] pt-[30px] max-w-full px-[30px] lg:px-[100px] mx-auto'>
                <div className='flex flex-col lg:flex-row relative items-center'>
                    <div className="w-full lg:w-3/4 py-5 pr-5">
                        <motion.div
                            initial="hidden"
                            whileInView="visible"
                            variants={{
                                hidden: { opacity: 0, y: -200 },
                                visible: { opacity: 1, y: 0 },
                            }}
                            transition={{ duration: 0.5 }}
                        >
                            <h2 className="text-lg md:text-[32px] leading-[24px] md:leading-[32px] font-medium pt-10 mb-2">
                                <span className="font-bold">BLOG</span>
                            </h2>
                        </motion.div>
                    </div>
                </div>
                <div className="flex flex-col lg:flex-row relative">
                    <div className="w-full lg:w-3/4 pb-5 border-r">
                        <div className="grid grid-cols-1 md:grid-cols-2">
                            {currentPosts.map(post => (
                                <motion.div
                                    key={post.id}
                                    className="bg-white pr-7 pt-4"
                                    initial="hidden"
                                    whileInView="visible"
                                    variants={{
                                        hidden: { opacity: 0, x: -200 },
                                        visible: { opacity: 1, x: 0 },
                                    }}
                                    transition={{ duration: 0.5, delay: 0.3 }}
                                >
                                    <div className='w-full h-[200px] bg-gray-300 rounded-md mb-4'></div>
                                    <h2 className="text-lg font-bold mb-2">
                                        {post.title.rendered}
                                    </h2>
                                    <div className="text-[#F89D38] text-sm mb-2 flex justify-between">
                                        <span>{Math.ceil(post.content.rendered.split(' ').length / 200)} min de lectura</span>
                                        <span>{new Date(post.date).toLocaleDateString()}</span>
                                    </div>
                                    <p className="text-sm text-gray-600 mb-4" dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} />
                                    <Link to={`/blog/${post.id}`} className="text-[#F89D38] text-sm font-semibold flex items-center gap-2">Leer más <img src="/images/icons/arrow-right-orange.svg" alt="Leer más" className="h-4 w-4" /></Link>
                                </motion.div>
                            ))}
                        </div>
                        <div className="flex justify-between mt-8 pr-7">
                            <button onClick={handlePreviousPage} disabled={currentPage === 1} className="px-4 py-2 bg-[#000000] hover:bg-[#000000cc] disabled:hover:bg-[#000] disabled:opacity-40 text-white">
                                Anterior
                            </button>
                            <button onClick={handleNextPage} disabled={currentPage === totalPages} className="px-4 py-2 bg-[#000000] hover:bg-[#000000cc] disabled:hover:bg-[#000] disabled:opacity-40 text-white">
                                Siguiente
                            </button>
                        </div>
                    </div>

                    <div className="w-full lg:w-1/4 pt-4 md:p-4 sticky md:top-[140px] h-[400px]">
                        <h3 className="md:mt-[-80px] mb-12 text-right bg-black text-white border border-black transition-all duration-500 hover:bg-white hover:text-black p-1 px-3">Últimos <span className='font-bold'>Artículos</span></h3>
                        {recentPosts.map(post => (
                            <motion.div
                                key={post.id} 
                                className="mb-4"
                                initial="hidden"
                                whileInView="visible"
                                variants={{
                                    hidden: { opacity: 0, y: -200 },
                                    visible: { opacity: 1, y: 0 },
                                }}
                                transition={{ duration: 0.5 }}
                            >
                                <Link to={`/blog/${post.id}`} className='flex items-center' >
                                    <div className="flex-shrink-0 w-[60px] h-[60px] bg-gray-300 rounded"></div>
                                    <div className="ml-4">
                                    <h4 className="text-sm font-medium uppercase">
                                        {post.title.rendered}
                                    </h4>
                                    </div>
                                </Link>
                            </motion.div>
                        ))}
                    </div>
                </div>
            </div>        
        </div>
    );
};

export default Blog;