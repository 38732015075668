import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const Header = ({active}) => {

    const location = useLocation();
    const [openMenu, setOpenMenu ] = useState(false);
    const [submenuServicios, setSubmenuServicios] = useState(false)

    return (
        <header className='py-[30px] lg:py-[35px] bg-black overflow-x-hidden max-w-full w-full'>
            <div className='bg-black fixed z-[9999999] top-0 w-full'>
                <div className="py-7 flex justify-between items-center w-[1280px] 2xl:w-[1440px] max-w-full px-[30px] lg:px-[100px] mx-auto">
                    <a href={`${process.env.PUBLIC_URL}/`}>
                        <img src={`${process.env.PUBLIC_URL}/images/logo.png`} className='w-28 lg:w-[200px]' alt="" />
                    </a>
                    <nav className={`${openMenu? 'right-0' : 'right-[-500px]'} max-lg:absolute max-lg:w-[500px] max-lg:max-w-full transition-all max-lg:top-0 max-lg:h-[100vh] max-lg:bg-black max-lg:justify-center max-lg:items-center max-lg:flex max-lg:flex-col`}>
                        <div className={`${openMenu? 'right-[10px]' : 'hidden'} lg:hidden fixed z-[90999] mb-10 top-[35px] w-[40px] h-[40px] text-white flex-col gap-0 cursor-pointer justify-center`} onClick={()=>(setOpenMenu(!openMenu))}>
                            <div className='w-6 h-[3px] rotate-45 mb-[-3px] bg-white'></div>
                            <div className='w-6 h-[3px] -rotate-45 bg-white'></div>
                        </div>
                        <ul className='flex max-lg:w-[300px] max-lg:flex-col justify-center items-center gap-3 lg:gap-6'>
                            <li className={`${location.pathname !== `${process.env.PUBLIC_URL}/` ? 'text-white' : 'text-[#F89D38]'} uppercase tracking-[3px] text-[12px]`}>
                                <a href={`${process.env.PUBLIC_URL}/`}>
                                    Inicio
                                </a>
                            </li>
                            <li 
                                className={`${['/servicios/tour-virtual', '/servicios/marketing-digital', '/servicios/tecnologia-3d', '/servicios/matterport'].includes(location.pathname.replace(process.env.PUBLIC_URL, '')) ? 'text-[#F89D38]' : 'text-white'} uppercase tracking-[3px] text-[12px] relative cursor-pointer max-lg:text-center`} 
                                onClick={() => setSubmenuServicios(!submenuServicios)}
                            >
                                <span className='max-md:hidden'>
                                    Servicios
                                </span>
                                <ul className={`${submenuServicios ? '' : 'lg:hidden'} max-lg:text-center flex flex-col bg-black px-4 max-lg:pb-0 absolute max-lg:relative w-[210px]`}>
                                    <li className={`${['/servicios/tour-virtual'].includes(location.pathname.replace(process.env.PUBLIC_URL, '')) ? 'text-[#F89D38]' : 'text-white'} uppercase tracking-[3px] text-[12px]`}>
                                        <a className='max-md:pt-[0px] py-[6px] pt-[12px] inline-flex' href={`${process.env.PUBLIC_URL}/servicios/tour-virtual`}>
                                            Tour Virtual
                                        </a>
                                    </li>
                                    <li className={`${['/servicios/marketing-digital'].includes(location.pathname.replace(process.env.PUBLIC_URL, '')) ? 'text-[#F89D38]' : 'text-white'} uppercase tracking-[3px] text-[12px]`}>
                                        <a className='py-[6px] inline-flex' href={`${process.env.PUBLIC_URL}/servicios/marketing-digital`}>
                                            Marketing Digital
                                        </a>
                                    </li>
                                    <li className={`${['/servicios/tecnologia-3d'].includes(location.pathname.replace(process.env.PUBLIC_URL, '')) ? 'text-[#F89D38]' : 'text-white'} uppercase tracking-[3px] text-[12px]`}>
                                        <a className='py-[6px] inline-flex' href={`${process.env.PUBLIC_URL}/servicios/tecnologia-3d`}>
                                            Tecnología 3D
                                        </a>
                                    </li>
                                    <li className={`${['/servicios/matterport'].includes(location.pathname.replace(process.env.PUBLIC_URL, '')) ? 'text-[#F89D38]' : 'text-white'} uppercase tracking-[3px] text-[12px]`}>
                                        <a className='max-md:pb-[0px] pt-[6px] pb-[12px] inline-flex' href={`${process.env.PUBLIC_URL}/servicios/matterport`}>
                                            Matterport
                                        </a>
                                    </li>
                                </ul>
                            </li>
                            {/*<li className='text-white uppercase tracking-[3px] text-[12px]'>
                                <a href={`${process.env.PUBLIC_URL}/proyectos`}>
                                    Proyectos
                                </a>
                            </li>*/}
                            <li className={`${location.pathname.replace(process.env.PUBLIC_URL, '').startsWith('/blog') ? 'text-[#F89D38]' : 'text-white'} uppercase tracking-[3px] text-[12px]`}>
                                <a href={`${process.env.PUBLIC_URL}/blog`}>
                                    Blog
                                </a>
                            </li>
                            <li className={`${location.pathname !== `${process.env.PUBLIC_URL}/contacto` ? 'text-white' : 'text-[#F89D38]'} uppercase tracking-[3px] text-[12px] `}>
                                <a href={`${process.env.PUBLIC_URL}/contacto`}>
                                    Contacto
                                </a>
                            </li>
                        </ul>
                    </nav>
                    <div className='lg:hidden flex flex-col gap-1 cursor-pointer h-[30px] justify-center' onClick={()=>(setOpenMenu(!openMenu))}>
                        <div className='w-5 h-[2px] bg-white'></div>
                        <div className='w-5 h-[2px] bg-white'></div>
                        <div className='w-5 h-[2px] bg-white'></div>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;